.container {
  margin: 0 1rem;
}

.post {
  max-width: 69rem;
  margin: auto;
}

.post__info {
  display: flex;
  margin-bottom: 1rem;
}

.post__info img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.post__info div {
  margin-left: 1rem;
}

.post__hash-tags {
  margin: 2rem 0;
  font-weight: 600;
  color: #7f95eb;
}

.post__img {
  max-width: 100%;
  border-radius: 35px;
}
