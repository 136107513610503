.nav {
  background-color: white;
}

.nav::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 4px;
  background: var(--color-accent);
}

.nav__wrapper {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90rem;
  padding: 1rem;
}

.nav__wrapper__internal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav img {
  max-width: 15rem;
  margin-right: 1rem;
}

.nav svg {
  font-size: 2.2rem;
  padding: 0.5rem;
  pointer-events: none;
}

.nav__wrapper__internal__input {
  max-width: 20rem;
}

.nav__wrapper__internal__input input {
  width: 100%;
}

.nav__wrapper__button--burger {
  display: none;
}

@media (max-width: 490.98px) {
  .nav img {
    max-width: 11rem;
  }

  .nav__wrapper__button--burger {
    display: inline;
  }

  .nav__wrapper__button--burger--active {
    color: var(--color-accent);
  }

  .nav__wrapper__menu {
    position: absolute;
    right: 0;
    top: 7rem;
    background-color: white;
    border-radius: 35px;
    padding: 1rem;
    z-index: 3;
    filter: drop-shadow(0px 4px 4px rgba(201, 199, 199, 0.25));
    display: none;
  }

  .nav__wrapper__menu--active-on-mobile {
    display: inline;
  }
}
