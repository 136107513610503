.container {
  margin: 2rem 1rem;
}

.welcome {
  position: relative;
  max-width: 90rem;
  background: linear-gradient(91.02deg, #fba6f2 5.7%, #7f95eb 85.44%, #94bfff 105.77%);
  margin: auto;
}

svg {
  font-size: 2rem;
}
