@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  --color-primary: black;
  --color-secondary: #f2f2f2;
  --color-accent: #20ebae;
}

html {
  font-size: 62.5%;
  background-color: var(--color-secondary);
}

body {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 2rem;
}

h1,
h2,
h3 {
  font-size: 2.4rem;
}

h4,
h5 {
  font-size: 1.7rem;
}

h6 {
  font-size: 1.1rem;
}

p,
input,
a,
button,
textarea {
  font-size: 1.7rem;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  border-radius: 35px;
  color: var(--color-primary);
}

button:hover {
  background-color: var(--color-secondary);
}

input,
textarea {
  padding: 1rem 2rem;
  outline: none;
  border: none;
  background-color: var(--color-secondary);
  border-radius: 35px;
  font-family: 'Poppins', sans-serif;
}
