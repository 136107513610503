.post-list {
  margin-top: 5rem;
}

.post-list__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 2rem;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
}
