.container {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.register {
  max-width: 60rem;
  position: relative;
  margin: 0 1rem;
}

.register__boomGraphic {
  position: absolute;
  max-width: 10rem;
  top: -5%;
  left: -5%;
}

.register__brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register__brand-logo--img {
  max-width: 25rem;
  margin-bottom: 4rem;
}

#register__form__input--file {
  display: none;
}

.register__form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.register__form input {
  margin-right: 1.5rem;
  margin-bottom: 2rem;
}

.register__form__wrapper {
  position: relative;
}

.register__form__wrapper label {
  position: absolute;
  right: 10%;
  font-size: 2rem;
  color: red;
}

.register__form__input--file--label {
  cursor: pointer;
  background: linear-gradient(91.02deg, #fba6f2 5.7%, #7f95eb 85.44%, #94bfff 105.77%);
  color: white;
  font-weight: 700;
  padding: 1.3rem 2rem;
  filter: drop-shadow(0px 4px 4px rgba(201, 199, 199, 0.25));
  border-radius: 35px;
  height: fit-content;
}

.register__form__input--file--output-img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 1rem;
}

.register__form__input--file--label:hover {
  color: var(--color-secondary);
}

.register__form__submit-btn {
  margin-top: 3rem;
}

@media (max-width: 600.98px) {
  .container {
    margin-top: 4rem;
  }

  .register__boomGraphic {
    display: none;
  }
}

@media (max-width: 354.98px) {
  .register__brand-logo--img {
    max-width: 20rem;
  }

  .register {
    padding: 2rem !important;
  }
}
